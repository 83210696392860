import { mapActions, mapGetters, mapMutations } from "vuex"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"
import ThemisInput from "@/components/shared/input"
import { MAX_CHARACTER_LIMIT } from "@/constants"

export default {
  name      : "ReplyTemplates",
  components: {
    ThemisInput
  },
  data: () => ({
    showAddReplyTemplateDialog        : false,
    replyTemplateNameCharacterLimit   : MAX_CHARACTER_LIMIT.REPLY_TEMPLATE_NAME,
    replyTemplateContentCharacterLimit: MAX_CHARACTER_LIMIT.REPLY_TEMPLATE_CONTENT,
    isReplyTemplateNameDuplicate      : false,
    isReplyTemplateFocused            : false,
    replyTemplate                     : {
      name   : null,
      content: null
    }
  }),
  computed: {
    ...mapGetters({
      replyTemplates       : "replyTemplates/replyTemplates",
      isAddingReplyTemplate: "replyTemplates/isAddingReplyTemplate",
      isReplyTemplateAdded : "replyTemplates/isReplyTemplateAdded",
      replyTemplateAddError: "replyTemplates/replyTemplateAddError"
    }),
    replyTemplatesHelpCenterUrl() {
      return process.env.VUE_APP_REPLY_TEMPLATES_HELP_URL
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.REPLY_TEMPLATES, this.$t.bind(this))
    },
    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.REPLY_TEMPLATES, this.$t.bind(this))
    },
    itemsForTable() {
      return this.replyTemplates.map(replyTemplates => {
        return {
          id  : replyTemplates.id,
          name: replyTemplates.name
        }
      })
    },
    classForReplyTemplatesHelpText() {
      return this.replyTemplates.length ? "body-1" : "info--text text--lighten-1"
    },
    classForReplyTemplateButton() {
      return this.replyTemplates.length ? "float-right" : ""
    }
  },
  methods: {
    ...mapActions({
      addReplyTemplate: "replyTemplates/addReplyTemplate"
    }),
    ...mapMutations({
      setReplyTemplateAddError: "replyTemplates/setReplyTemplateAddError"
    }),
    handleCancelAddReplyTemplate() {
      this.showAddReplyTemplateDialog = false
      this.replyTemplate              = {
        name   : null,
        content: null
      }
    },
    handleAddReplyTemplate() {
      this.addReplyTemplate({
        name   : this.replyTemplate.name,
        content: this.replyTemplate.content
      })
    },
    handleReplyTemplateContentInputOnBlurEvent(onBlur) {
      onBlur()
      this.isReplyTemplateFocused = false
    },
    handleReplyTemplateContentInputOnFocusEvent(onFocus) {
      onFocus()
      this.isReplyTemplateFocused = true
    }
  },
  watch: {
    "replyTemplate.name": {
      handler: function() {
        if (this.isReplyTemplateNameDuplicate) {
          this.setReplyTemplateAddError(undefined)
        }
      }
    },
    replyTemplateAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.field === "name" && newValue.type === "duplicate") {
          this.isReplyTemplateNameDuplicate = true
        } else {
          this.isReplyTemplateNameDuplicate = false
        }
      }
    },
    isReplyTemplateAdded: {
      handler: function(newValue) {
        if (newValue) {
          const replyTemplate             = this.replyTemplates.find(replyTemplate =>
            replyTemplate.name === this.replyTemplate.name
          )
          this.showAddReplyTemplateDialog = false
          this.replyTemplate              = {
            name   : null,
            content: null
          }
          this.$router.push({
            name  : "reply-template",
            params: {
              id: replyTemplate.id
            }
          })
        }
      }
    }
  }
}
